import { memo, useState } from "react";
import { motion } from "framer-motion";
import { FullLogoSvg } from "../../configs";
import classNames from "./homeSidebar.module.scss";
import angleLeft from "../../static/images/clipIcons/angleLeft.svg";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../LoginModal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLoginData, logout } from "../../app/loginSlice";

function HomeSidebar({ setSidebar }: { setSidebar: (val: boolean) => void }) {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector(selectLoginData);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loginModalOpen, setLoginModalOpen] = useState<string | boolean>(false);
  return (
    <>
      <motion.div
        initial={{ x: -400, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -400, opacity: 0 }}
        className={classNames.homeSidebar}
      >
        <div className={classNames.circle} onClick={() => setSidebar(false)}>
          <img src={angleLeft} alt="" />
        </div>
        <div className={classNames.logoWrap}>
          <FullLogoSvg
            className={classNames.logo}
            onClick={() => navigate("/")}
          />
        </div>
        <div className={classNames.menuList}>
          <div
            className={`${classNames.menuItem} ${
              classNames[(pathname === "/").toString()]
            }`}
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </div>
          <div
            className={`${classNames.menuItem} ${
              classNames[(pathname === "/mytickets").toString()]
            }`}
            onClick={() => {
              if (email) {
                navigate("/mytickets");
              } else setLoginModalOpen("/mytickets");
            }}
          >
            Tickets
          </div>
          <div
            className={classNames.menuItem}
            onClick={() => {
              if (email) {
                navigate("/memmories");
              } else setLoginModalOpen("/memmories");
            }}
          >
            Memories
          </div>
          <div
            className={classNames.menuItem}
            onClick={() => {
              // if (email) {
              //   navigate("/memmories");
              // } else setLoginModalOpen("/memmories");
            }}
          >
            Appearences
          </div>
        </div>
        {email ? (
          <div className={classNames.footerBtns}>
            <div className={classNames.btnLogin} onClick={() => {}}>
              <span>Invite Friends</span>
            </div>
            <div
              className={classNames.btnReg}
              onClick={() => {
                dispatch(logout());
              }}
            >
              <span>Logout</span>
            </div>
          </div>
        ) : (
          <div className={classNames.footerBtns}>
            <div
              className={classNames.btnLogin}
              onClick={() => {
                setLoginModalOpen("/");
              }}
            >
              <span>Login</span>
            </div>
            <div
              className={classNames.btnReg}
              onClick={() => {
                navigate("/register");
              }}
            >
              <span>Register</span>
            </div>
          </div>
        )}
      </motion.div>
      {loginModalOpen && (
        <LoginModal
          onClose={() => setLoginModalOpen(false)}
          onSuccess={() => {
            navigate(loginModalOpen.toString());
            setLoginModalOpen(false);
            setSidebar(false);
          }}
        />
      )}
    </>
  );
}

export default memo(HomeSidebar);
