import { memo, useMemo, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import classNames from "./trendingTicketsHome.module.scss";
import { config } from "react-spring";
import Skeleton from "react-loading-skeleton";
import { FormatCurrency } from "../../utils/FunctionTools";
import addUser from "../../static/images/clipIcons/addUser.svg";
import { useNavigate } from "react-router-dom";

function TrendingTicketsHome({
  allTickets,
  tcketsLoading,
  search,
}: {
  allTickets: any[];
  tcketsLoading: boolean;
  search: string;
}) {
  const navigate = useNavigate();
  const [selectIndex, setSelectIndex] = useState(0);

  const slides = useMemo(() => {
    if (tcketsLoading) {
      return Array(10)
        .fill("")
        .map((_, i) => ({
          key: i,
          content: (
            <div className={classNames.card}>
              <div className={classNames.headerArea}>
                <div className={classNames.img}>
                  <Skeleton height={68} width={68} circle />
                </div>
              </div>
              <div className={classNames.headerPreview}>
                <Skeleton className={classNames.title} width={200} />
                <div className={classNames.by}>
                  <Skeleton width={20} />{" "}
                  <Skeleton className={classNames.byImg} circle />{" "}
                  <Skeleton width={60} />
                </div>
              </div>
              <div className={classNames.content}>
                <div className={classNames.price}>
                  <Skeleton
                    className={classNames.label}
                    width={80}
                    style={{ marginBottom: 10 }}
                  />
                  <Skeleton className={classNames.value} width={120} />
                </div>
                <div className={classNames.supply}>
                  <Skeleton
                    className={classNames.label}
                    width={80}
                    style={{ marginBottom: 10 }}
                  />
                  <Skeleton className={classNames.value} width={120} />
                </div>
              </div>
              <div className={classNames.footer}>
                <Skeleton className={classNames.country} width={120} />
                <Skeleton className={classNames.circulation} width={120} />
              </div>
            </div>
          ),
        }));
    }
    return allTickets
      ?.filter(
        (ticket: any) =>
          ticket?.token_profile_data?.coinSymbol
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          ticket?.token_profile_data?.coinName
            .toLowerCase()
            .includes(search.toLowerCase())
      )
      .map((ticket: any, index) => ({
        key: ticket._id,
        content: (
          <div className={classNames.card}>
            <div
              className={classNames.headerArea}
              style={{
                backgroundColor: `#${ticket?.token_profile_data?.color_codes?.[0]}`,
              }}
            >
              <div className={classNames.img}>
                <img src={ticket?.token_profile_data?.coinImage} alt="" />
              </div>
            </div>
            <div className={classNames.headerPreview}>
              <div className={classNames.title}>
                {ticket?.token_profile_data?.coinName}
              </div>
              <div className={classNames.by}>
                By{" "}
                <img
                  src={ticket?.banker_metaData?.profilePicURL}
                  alt=""
                  className={classNames.byImg}
                />{" "}
                <b>{ticket?.banker_metaData?.displayName}</b>
              </div>
            </div>
            <div className={classNames.content}>
              <div className={classNames.price}>
                <div className={classNames.label}>Current Price</div>
                <div className={classNames.value}>
                  {FormatCurrency(ticket?.token_price, ticket?.asset)}{" "}
                  <small>{ticket?.asset}</small>
                </div>
              </div>
              <div className={classNames.supply}>
                <div className={classNames.label}>Supply</div>
                <div className={classNames.value}>
                  {ticket?.tokens_available}/{ticket?.token_totalSupply}
                </div>
              </div>
            </div>
            <div className={classNames.footer}>
              <div className={classNames.country}>#{ticket?.country}</div>
              <div className={classNames.circulation}>
                <img src={addUser} alt="" />{" "}
                <span>{ticket?.circulating_supply}</span>
              </div>
            </div>
          </div>
        ),
        onClick: () => {
          if (index === selectIndex) {
            navigate(`/cart/${ticket?.token_profile_data?.coinSymbol}`);
          } else {
            setSelectIndex(index);
          }
        },
      }));
  }, [allTickets, navigate, search, selectIndex, tcketsLoading]);

  return (
    <div className={classNames.trending}>
      <div className={classNames.trendingTitle}>Trending Tickets</div>
      <div className={classNames.trendingSubtitle}>
        These are the hottest events in your Area
      </div>
      <Carousel
        slides={slides}
        animationConfig={config.slow}
        goToSlide={selectIndex}
        offsetRadius={2}
        showNavigation={false}
      />
    </div>
  );
}

export default memo(TrendingTicketsHome);
