import React from "react";
import "./TopsSection.scss";
export default function TopSection() {
  return (
    <>
      <div className="sectionTop">
        <h1>
          We Are Revolutionizing <br />
          Event Financing
        </h1>
        <p>
          By enabling event organizers to tokenize their tickets and sell them
          to their future attendees who will finance, own and benefit from the
          success of the event.
        </p>
      </div>
      <div className="SectionSecond">
        <div className="overlap"></div>
        <div className="first">
          <h1>
            Im An <br />
            Event Organizer
          </h1>
        </div>
        <div className="second">
          <h1>
            Im An <br />
            Event Attender
          </h1>
        </div>
      </div>
    </>
  );
}
