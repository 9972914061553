import { Fragment, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectLoginData, updateTokenAsync } from "./app/loginSlice";
import Routes from "./Routes";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });

  // Update Token On Every Reload
  const dispatch = useAppDispatch();
  const { deviceId, refreshToken } = useAppSelector(selectLoginData);
  useEffect(() => {
    dispatch(updateTokenAsync(deviceId, refreshToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
