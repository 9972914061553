import classNames from "./shrinkingNavbar.module.scss";
import { motion, useTransform, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { FullLogoSvg, LogoIconSVG } from "../../configs";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectLoginData } from "../../app/loginSlice";
import { useUserDetails } from "../../queryHooks";
import SettingsModal from "../SettingsModal";
import LoginModal from "../LoginModal";
import useWindowDimensions from "../../utils/WindowSize";

function ShrinkingNavbar({
  scrollY,
  active,
  sidebar,
  setSidebar,
  onSearchChange = () => {},
}: {
  scrollY: any;
  active: boolean;
  sidebar: boolean;
  setSidebar: (val: boolean) => void;
  onSearchChange: (val: string) => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { email } = useAppSelector(selectLoginData);
  const { data } = useUserDetails(email);
  const { width } = useWindowDimensions();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const height = useTransform(
    scrollY,
    [0, width > 768 ? 300 : 160],
    [width > 768 ? 300 : 160, 0]
  );
  const zoom = useTransform(scrollY, [0, width > 768 ? 300 : 160], [1, 0]);
  const color = useTransform(
    scrollY,
    [width > 768 ? 250 : 125, width > 768 ? 290 : 155],
    ["#ffffff", "#ffffff"]
  );
  const [search, setSearch] = useState("");
  useEffect(() => {
    onSearchChange(search);
  }, [onSearchChange, search]);

  return (
    <>
      <motion.div
        className={`${classNames[active.toString()]} ${classNames.navWrap} ${
          sidebar && classNames.sideOpen
        }`}
        style={{
          background: color,
        }}
      >
        <nav className={classNames.navBar}>
          {!sidebar && (
            <>
              <div
                onClick={() => setSidebar(!sidebar)}
                className={`${classNames.hamburger} ${
                  classNames.hamburgerSqueeze
                } ${sidebar ? classNames.isActive : ""}`}
              >
                <span className={classNames.hamburgerBox}>
                  <span className={classNames.hamburgerInner}></span>
                </span>
              </div>
              <FullLogoSvg className={classNames.logo} />
            </>
          )}
          {active && (
            <motion.div layoutId="searchWrap" className={classNames.searcWrap}>
              <input
                type="text"
                className={classNames.search}
                placeholder="Search For Events..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/cart/${search}`);
                  }
                }}
              />
            </motion.div>
          )}
          {email ? (
            <div
              className={classNames.user}
              onClick={() => setSettingsModalOpen(true)}
            >
              <img src={data?.profile_img} alt="" className={classNames.dp} />
              <span>{data?.name}</span>
            </div>
          ) : (
            <div
              className={classNames.btnLogin}
              onClick={() => setLoginModalOpen(true)}
            >
              Login
            </div>
          )}
        </nav>
        {/* <motion.div
          style={{
            height,
          }}
          transition={{ ease: "easeOut", duration: 0.1 }}
          className={classNames.navDetail}
        >
          <motion.div
            style={{
              zoom,
            }}
            transition={{ ease: "easeOut", duration: 0.1 }}
            className={classNames.navDetailTexts}
          >
            <div className={classNames.subtitle}>
              A New Way To Experience Events Has Arrived
            </div>
            <div className={classNames.title}>
              Unlock The Hottest Events By Collecting NFT’s
            </div>
          </motion.div>
          <div className={classNames.spacer} />
          <AnimatePresence>
            {!active && (
              <motion.div
                className={classNames.searcWrap}
                layoutId="searchWrap"
              >
                <LogoIconSVG
                  className={classNames.icon}
                  onClick={() => {
                    navigate(`/cart/${search}`);
                  }}
                />
                <input
                  type="text"
                  className={classNames.input}
                  placeholder="Search For Events In India.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/cart/${search}`);
                    }
                  }}
                />
                <div className={classNames.spacerLine} />
                <span>India</span>
                <div className={classNames.spacerLine} />
                <span
                  onClick={() => {
                    navigate(`/cart/${search}`);
                  }}
                >
                  Search
                </span>
              </motion.div>
            )} 
          </AnimatePresence>
        </motion.div> */}
      </motion.div>
      <div className={classNames.fullNavSpacer}></div>
      {settingsModalOpen && (
        <SettingsModal
          onClose={() => setSettingsModalOpen(false)}
          onSuccess={() => {}}
        />
      )}
      {loginModalOpen && (
        <LoginModal
          onClose={() => setLoginModalOpen(false)}
          onSuccess={() => {
            setLoginModalOpen(false);
          }}
        />
      )}
    </>
  );
}

export default ShrinkingNavbar;
