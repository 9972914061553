import { memo } from "react";
import classNames from "./exploreHome.module.scss";

import concerts from "../../static/images/exploreBgs/concerts.jpg";
import conferences from "../../static/images/exploreBgs/conferences.jpg";
import networking from "../../static/images/exploreBgs/networking.jpg";
import nightLife from "../../static/images/exploreBgs/nightLife.jpg";
import sports from "../../static/images/exploreBgs/sports.jpg";

const IMAGE_CARDS = [
  {
    title: "Networking",
    img: networking,
  },
  {
    title: "Conferences",
    img: conferences,
  },
  {
    title: "Concerts",
    img: concerts,
  },
  {
    title: "Nightlife",
    img: nightLife,
  },
  {
    title: "Sports",
    img: sports,
  },
];

function ExploreHome() {
  return (
    <div className={classNames.explore}>
      <div className={classNames.expTitle}>Explore Hubs</div>
      <div className={classNames.hubList}>
        {IMAGE_CARDS.map((card, i) => (
          <div
            className={classNames.hubCard}
            key={i}
            style={{ backgroundImage: `url(${card.img})` }}
          >
            <div className={classNames.shade}>
              <span>{card.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(ExploreHome);
