import { useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import classNames from "./cartPage.module.scss";
import { FULL_LOGO, FULL_LOGO_TWO } from "../../configs";
import { Navigate, useParams } from "react-router-dom";
import { useTicketDetail } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import closeRed from "../../static/images/icons/closeRed.svg";
import Skeleton from "react-loading-skeleton";
import LoginCard from "../../components/LoginModal/LoginCard";
import { toast } from "react-toastify";
import NavbarStable from "../../components/NavbarStable";
import { useAppSelector } from "../../app/hooks";
import { selectLoginData } from "../../app/loginSlice";
import PaymentSidebar from "../../components/PaymentSidebar";
import { AnimatePresence } from "framer-motion";
import HomeSidebar from "../../components/HomeSidebar";
import angleUp from "../../static/images/clipIcons/angleUp.svg";
import useWindowDimensions from "../../utils/WindowSize";

function CartPage(): JSX.Element {
  const { tokenname } = useParams();
  const { height } = useWindowDimensions();
  const { data, isLoading, isError } = useTicketDetail(
    tokenname?.toUpperCase()
  );
  const [count, setCount] = useState<number>(1);
  const { email } = useAppSelector(selectLoginData);
  const [sidebar, setSidebar] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [cartClose, setCartClose] = useState(false);
  if (isError) {
    toast.error("Invalid Token", {
      toastId: "customId",
    });
    return <Navigate to="/" />;
  }
  return (
    <div
      className={classNames.cartPage}
      style={{
        maxHeight: isMobile ? height : "unset",
      }}
    >
      <AnimatePresence initial={false}>
        {sidebar && <HomeSidebar setSidebar={setSidebar} />}
      </AnimatePresence>
      <div className={classNames.pageContent}>
        <NavbarStable sidebar={sidebar} setSidebar={setSidebar} />
        <div className={classNames.header}>
          <div className={classNames.texts}>
            <div className={classNames.titleHead}>Ticket Cart</div>
            <div className={classNames.subTitleHead}>
              You Have One Ticket In Your Cart
            </div>
          </div>
          {isLoading ? (
            <div className={classNames.btnAsset}>
              <Skeleton height={25} width={25} circle />
              <Skeleton width={50} />
            </div>
          ) : (
            <div className={classNames.btnAsset}>
              <img src={data?.asset_metaData?.coinImage} alt="" />
              <span>{data?.asset}</span>
            </div>
          )}
        </div>
        <div className={classNames.cartList}>
          <div className={classNames.cartItmCard}>
            {isLoading ? (
              <div className={classNames.coinDetail}>
                <Skeleton
                  height={80}
                  width={80}
                  circle
                  style={{ marginRight: 20 }}
                />
                <div className={classNames.cardText}>
                  <Skeleton
                    width={200}
                    className={classNames.name}
                    style={{ marginBottom: 5 }}
                  />
                  <Skeleton width={200} className={classNames.symbool} />
                </div>
              </div>
            ) : (
              <div className={classNames.coinDetail}>
                <img src={data?.token_profile_data?.coinImage} alt="" />
                <div className={classNames.cardText}>
                  <div className={classNames.name}>
                    {data?.token_profile_data?.coinName}
                  </div>
                  <div className={classNames.symbool}>
                    ({data?.token_profile_data?.coinSymbol})
                  </div>
                  <div className={classNames.amountMobile}>
                    {FormatCurrency(count * data?.token_price, data?.asset)}{" "}
                    {data?.asset}
                  </div>
                </div>
              </div>
            )}
            <div className={classNames.count}>
              <div
                className={classNames.btnMinus}
                onClick={() => {
                  if (count > 1) setCount(count - 1);
                }}
              >
                -
              </div>
              <input
                type="number"
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className={classNames.input}
              />
              <div
                className={classNames.btnPlus}
                onClick={() => {
                  setCount(count + 1);
                }}
              >
                +
              </div>
            </div>
            {isLoading ? (
              <Skeleton className={classNames.amount} width={200} />
            ) : (
              <div className={classNames.amount}>
                {FormatCurrency(count * data?.token_price, data?.asset)}{" "}
                {data?.asset}
              </div>
            )}
            <Link to="/" className={classNames.closeCircle}>
              <img src={closeRed} alt="" />
            </Link>
          </div>
        </div>
        {!loginOpen &&
          !cartClose &&
          (email ? (
            <div className={classNames.footerButtons}>
              <div
                className={classNames.btnRegister}
                onClick={() => {
                  setCartClose(true);
                }}
              >
                Begin Checkout
              </div>
            </div>
          ) : (
            <div className={classNames.footerButtons}>
              <div
                className={classNames.btnLogin}
                onClick={() => {
                  setCartClose(true);
                  setLoginOpen(true);
                }}
              >
                Login
              </div>
              <div className={classNames.btnRegister} onClick={() => {}}>
                Register
              </div>
            </div>
          ))}
        {(loginOpen || cartClose) && (
          <div
            className={classNames.footerShowHide}
            onClick={() => {
              setCartClose(!cartClose);
            }}
          >
            Your Cart{" "}
            <img
              src={angleUp}
              alt=""
              className={classNames[cartClose.toString()]}
            />
          </div>
        )}
      </div>
      <aside
        className={`${classNames.sideBar} ${classNames[cartClose.toString()]}`}
      >
        {email ? (
          <PaymentSidebar
            data={data}
            count={count}
            onClose={() => setLoginOpen(false)}
          />
        ) : (
          <SidebarLogin loginOpen={loginOpen} setLoginOpen={setLoginOpen} />
        )}
      </aside>
    </div>
  );
}

function SidebarLogin({
  loginOpen,
  setLoginOpen,
}: {
  loginOpen: boolean;
  setLoginOpen: (param: boolean) => void;
}) {
  return (
    <>
      <img
        src={loginOpen ? FULL_LOGO : FULL_LOGO_TWO}
        alt=""
        className={classNames.logo}
      />
      <LoginCard
        onSuccess={() => {
          toast.success("Logged In");
        }}
        bare={{
          className: classNames.bare,
          classNameBtn: classNames.btnLogin,
          text: "Login",
        }}
      />
    </>
  );
}

export default CartPage;
