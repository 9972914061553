import { useNavigate } from "react-router-dom";
import classNames from "./navbar.module.scss";
import { FullLogoSvg } from "../../configs";
import { useAppSelector } from "../../app/hooks";
import { selectLoginData } from "../../app/loginSlice";
import { useUserDetails } from "../../queryHooks";

function NavbarStable({
  sidebar,
  setSidebar = (param) => {},
}: {
  sidebar?: boolean;
  setSidebar?: (param: boolean) => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { email } = useAppSelector(selectLoginData);
  const { data } = useUserDetails(email);
  return (
    <>
      <div className={`${classNames.true} ${classNames.navWrap}`}>
        <nav className={classNames.navBar}>
          <div
            onClick={() => {
              setSidebar(!sidebar);
            }}
            className={`${classNames.hamburger} ${
              classNames.hamburgerSqueeze
            } ${sidebar ? classNames.isActive : ""}`}
          >
            <span className={classNames.hamburgerBox}>
              <span className={classNames.hamburgerInner}></span>
            </span>
          </div>
          <FullLogoSvg
            className={classNames.logo}
            onClick={() => navigate("/")}
          />
          {email && (
            <div className={classNames.user}>
              <img src={data?.profile_img} alt="" className={classNames.dp} />
              <span>{data?.name}</span>
            </div>
          )}
        </nav>
      </div>
      <div className={classNames.spacer}></div>
    </>
  );
}

export default NavbarStable;
