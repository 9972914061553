import { memo } from "react";
import { motion } from "framer-motion";
import { useLottie } from "lottie-react";
import eventMapLottie from "../../static/animations/eventMapLottie.json";
import classNames from "./loadingAnimation.module.scss";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "#22212800",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "#222128ff",
  },
};

function LoadingAnimation({
  className,
  classNameSvg,
  logoAnim = false,
}: {
  className?: string;
  classNameSvg?: string;
  logoAnim?: boolean;
}) {
  const options = {
    animationData: eventMapLottie,
    loop: true,
    autoplay: true,
    className: `${classNames.logo} ${classNameSvg}`,
  };
  const { View } = useLottie(options);
  return (
    <div className={`${classNames.loadingAnimation} ${className}`}>
      {logoAnim ? (
        <motion.svg
          className={`${classNames.logo} ${classNameSvg}`}
          viewBox="0 0 65 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M0 30V60H15H30V56.1201V52.2398H18.4366H6.87318V51.8768C6.87318 51.3955 6.87318 7.8451 6.87318 7.79684V7.76021H18.4366H30V3.8801V0H15H0V30Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M34.6153 3.8801V7.76021H46.0008H57.3863V7.93005C57.3863 8.14987 57.3863 52.1715 57.3863 52.2099C57.3863 52.2381 56.8347 52.2398 46.0008 52.2398H34.6153L34.6182 56.1149L34.6226 59.9916L49.3891 59.9948L64.1538 60V30V0H49.3846H34.6153V3.8801Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M11.5384 19.6154V26.3077H14.9796H18.4209V23.5932V20.8787H24.2104H29.9999V16.9009V12.9231H20.7692H11.5384V19.6154Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M34.6153 16.9009V20.8787H40.2602H45.905V21.4882C45.905 21.8245 45.905 23.0469 45.905 24.2027V26.3077H49.2603H52.6153V19.6154V12.9231H43.6154H34.6153V16.9009Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M23.077 30.2308V34.1538H26.5385H30L29.9971 30.2341L29.9926 26.3161L26.5355 26.3127L23.077 26.3077V30.2308Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M34.6153 30.2308V34.1538H38.0768H41.5384V30.2308V26.3094H38.0768L34.6153 26.3077V30.2308Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M11.5384 40.6145V47.0769H20.7692H29.9999V43.244V39.4111H24.2104H18.4209L18.4179 36.7849L18.4135 34.1604L14.9767 34.1571L11.5384 34.1538V40.6145Z"
            fill="#222128"
          />
          <motion.path
            stroke="#222128"
            strokeWidth={0.2}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut", repeat: Infinity },
              fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
            }}
            d="M45.9137 34.1654C45.9094 34.1719 45.905 34.4555 45.905 34.795C45.905 35.1362 45.905 36.3148 45.905 37.4126V39.4121H40.2602H34.6153V43.2445V47.0769H43.6154H52.6153V40.6154V34.1538L49.2689 34.1555C47.4287 34.1555 45.9194 34.1604 45.9137 34.1654Z"
            fill="#222128"
          />
        </motion.svg>
      ) : (
        View
      )}
    </div>
  );
}

export default memo(LoadingAnimation);
