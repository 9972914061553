import { BrowserRouter, Routes, Route } from "react-router-dom";
import CartPage from "./pages/CartPage";
import LandingPage from "./pages/LandingPage";
import MyTicketsPage from "./pages/MyTicketsPage";
import RegisterPage from "./pages/RegisterPage";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/mytickets" element={<MyTicketsPage />} />
        <Route path="/cart">
          <Route path=":tokenname" element={<CartPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
