import { memo } from "react";
import classNames from "./eventCreatorsHome.module.scss";

import indianInvestorIcon from "../../static/images/logos/indianInvestorIcon.svg";

function EventCreatorsHome() {
  return (
    <div className={classNames.explore}>
      <div className={classNames.expTitle}>Event Creators</div>
      <div className={classNames.hubList}>
        <div className={classNames.hubCard}>
          <img src={indianInvestorIcon} alt="" />
          <div className={classNames.footerText}>INR.Group</div>
        </div>
      </div>
    </div>
  );
}

export default memo(EventCreatorsHome);
