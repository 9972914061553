import { isMobile } from "react-device-detect";
import classNames from "./loginModal.module.scss";
import LoginCard from "./LoginCard";

function LoginModal({ onClose = () => {}, onSuccess = () => {} }) {
  if (isMobile) {
    return <LoginMobile onSuccess={onSuccess} />;
  }
  return (
    <div className={classNames.loginModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <LoginCard onSuccess={onSuccess} />
    </div>
  );
}

function LoginMobile({ onSuccess }: any) {
  return (
    <div className={classNames.loginMobile}>
      <LoginCard
        onSuccess={onSuccess}
        bare={{
          className: classNames.bare,
          classNameBtn: classNames.btnLogin,
          text: "Login",
        }}
      />
    </div>
  );
}

export default LoginModal;
