import { Fragment, useState } from "react";
import classNames from "./myTicketsPage.module.scss";
import NavbarStable from "../../components/NavbarStable";
import { AnimatePresence } from "framer-motion";
import HomeSidebar from "../../components/HomeSidebar";
import { useAllMyTickets } from "../../queryHooks";
import { useAppSelector } from "../../app/hooks";
import { selectLoginData } from "../../app/loginSlice";
import { FormatCurrency } from "../../utils/FunctionTools";

function MyTicketsPage(): JSX.Element {
  const [sidebar, setSidebar] = useState(false);
  const { email } = useAppSelector(selectLoginData);
  const { data: ticketData } = useAllMyTickets(email);
  return (
    <div className={classNames.myTicketsPage}>
      <AnimatePresence initial={false}>
        {sidebar && <HomeSidebar setSidebar={setSidebar} />}
      </AnimatePresence>
      <div className={classNames.pageContent}>
        <NavbarStable sidebar={sidebar} setSidebar={setSidebar} />
        <div className={classNames.header}>
          <div className={classNames.texts}>
            <div className={classNames.titleHead}>Your Ticket Vault</div>
            <div className={classNames.subTitleHead}>
              You Have {ticketData?.count} TicketCoins Across <b>All Apps</b>
            </div>
          </div>
          <div className={classNames.menu}>
            <div className={`${classNames.menuItem} ${classNames.true}`}>
              By Coin
            </div>
            <div className={`${classNames.menuItem} ${classNames.false}`}>
              By Event
            </div>
            <div className={`${classNames.menuItem} ${classNames.false}`}>
              By Organizer
            </div>
          </div>
        </div>
        {ticketData?.vaultsData?.map((vault: any) => (
          <Fragment key={vault?._id}>
            <div className={classNames.headTwo}>
              <span>
                In {ticketData?.appsData?.[vault?.app_code]?.app_name}
              </span>
              <span>{vault?.tokens?.length} Tickets</span>
            </div>
            <div className={classNames.headTwoMob}>
              You Have {vault?.tokens?.length} TicketsIn{" "}
              {ticketData?.appsData?.[vault?.app_code]?.app_name}
            </div>
            <div className={classNames.tokenList}>
              <div className={classNames.tokenListIn}>
                {vault?.tokens?.map((token: any) => (
                  <div className={classNames.card}>
                    <div className={classNames.headerArea}>
                      <div className={classNames.img}>
                        <img
                          src={token?.token_profile_data?.coinImage}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className={classNames.headerPreview}>
                      <div className={classNames.title}>
                        {token?.token_profile_data?.coinName}
                      </div>
                      <div className={classNames.count}>
                        ({token?.token_profile_data?.coinSymbol})
                      </div>
                    </div>
                    <div className={classNames.content}>
                      <div className={classNames.detailItem}>
                        <span className={classNames.label}>Tickets:</span>
                        <span className={classNames.value}>{token?.value}</span>
                      </div>
                      <div className={classNames.detailItem}>
                        <span className={classNames.label}>Coin Value:</span>
                        <span className={classNames.value}>
                          {FormatCurrency(token?.value_in_asset, token?.asset)}{" "}
                          {token?.asset}
                        </span>
                      </div>
                    </div>
                    <div className={classNames.footer}>
                      <div className={classNames.ftBtn}>Hash</div>
                      <div className={classNames.ftBtn}>Actions</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default MyTicketsPage;
