import { AnimatePresence, useElementScroll } from "framer-motion";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import classNames from "./landingPage.module.scss";
import ShrinkingNavbar from "../../components/ShrinkingNavbar";
import addUser from "../../static/images/clipIcons/addUser.svg";
import HomeSidebar from "../../components/HomeSidebar";
import useWindowDimensions from "../../utils/WindowSize";
import { useAllTickets } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import ExploreHome from "../../components/ExploreHome";
import EventCreatorsHome from "../../components/EventCreatorsHome";
import NavbarStable from "../../components/NavbarStable";
import { LogoIconSVG } from "../../configs";
import TrendingTicketsHome from "../../components/TrendingTicketsHome";
import TopSection from "./TopSection";
function LandingPage(): JSX.Element {
  const navigate = useNavigate();
  const ref = useRef<any>();
  const { scrollY } = useElementScroll(ref);
  const { width } = useWindowDimensions();
  const scrollHandle = useCallback(
    (event: any) => {
      if (event?.path[0]?.scrollTop > (width > 768 ? 280 : 150)) {
        setActive(true);
      } else {
        setActive(false);
      }
    },
    [width]
  );
  // Scroll Listner To Activate Navbar
  useLayoutEffect(() => {
    ref?.current?.addEventListener("scroll", scrollHandle);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref?.current?.removeEventListener("scroll", scrollHandle);
    };
  }, [scrollHandle]);

  const [active, setActive] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [search, setSearch] = useState("");

  const { data: allTickets = [], isLoading: tcketsLoading } = useAllTickets();
  return (
    <div className={classNames.landingPage}>
      <AnimatePresence exitBeforeEnter initial={false}>
        {sidebar && <HomeSidebar setSidebar={setSidebar} />}
      </AnimatePresence>
      <div className={classNames.pageWrap}>
        <div className={classNames.pageWrapIn} ref={ref}>
          {width > 768 ? (
            <ShrinkingNavbar
              scrollY={scrollY}
              active={active}
              sidebar={sidebar}
              setSidebar={setSidebar}
              onSearchChange={setSearch}
            />
          ) : (
            <NavbarStable sidebar={sidebar} setSidebar={setSidebar} />
          )}
          <TopSection/>
          {/* <div className={classNames.navDetail}>
            <div className={classNames.navDetailTexts}>
              <div className={classNames.title}>
                Unlock The Hottest Events By Collecting NFT’s
              </div>
            </div>
            <div className={classNames.spacer} />
            <div className={classNames.searcWrap} style={{ opacity: 1 }}>
              <LogoIconSVG className={classNames.icon} />
              <input
                type="text"
                className={classNames.input}
                placeholder="Search For Events In India.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div> */}
          {width <= 768 ? (
            <TrendingTicketsHome
              tcketsLoading={tcketsLoading}
              allTickets={allTickets}
              search={search}
            />
          ) : (
            <>
              <div className={classNames.title}>Trending Tickets</div>
              <div className={classNames.cardListWrap}>
                <div className={classNames.cardList}>
                  {tcketsLoading
                    ? Array(10)
                        .fill("")
                        .map((_, i) => (
                          <div className={classNames.card}>
                            <div className={classNames.headerArea}>
                              <div className={classNames.img}>
                                <Skeleton height={68} width={68} circle />
                              </div>
                            </div>
                            <div className={classNames.headerPreview}>
                              <Skeleton
                                className={classNames.title}
                                width={200}
                              />
                              <div className={classNames.by}>
                                <Skeleton width={20} />{" "}
                                <Skeleton className={classNames.byImg} circle />{" "}
                                <Skeleton width={60} />
                              </div>
                            </div>
                            <div className={classNames.content}>
                              <div className={classNames.price}>
                                <Skeleton
                                  className={classNames.label}
                                  width={80}
                                  style={{ marginBottom: 10 }}
                                />
                                <Skeleton
                                  className={classNames.value}
                                  width={120}
                                />
                              </div>
                              <div className={classNames.supply}>
                                <Skeleton
                                  className={classNames.label}
                                  width={80}
                                  style={{ marginBottom: 10 }}
                                />
                                <Skeleton
                                  className={classNames.value}
                                  width={120}
                                />
                              </div>
                            </div>
                            <div className={classNames.footer}>
                              <Skeleton
                                className={classNames.country}
                                width={120}
                              />
                              <Skeleton
                                className={classNames.circulation}
                                width={120}
                              />
                            </div>
                          </div>
                        ))
                    : allTickets
                        ?.filter(
                          (ticket: any) =>
                            ticket?.token_profile_data?.coinSymbol
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            ticket?.token_profile_data?.coinName
                              .toLowerCase()
                              .includes(search.toLowerCase())
                        )

                        .map((ticket: any) => (
                          <div
                            className={classNames.card}
                            onClick={() =>
                              navigate(
                                `/cart/${ticket?.token_profile_data?.coinSymbol}`
                              )
                            }
                          >
                            <div
                              className={classNames.headerArea}
                              style={{
                                backgroundColor: `#${ticket?.token_profile_data?.color_codes?.[0]}`,
                              }}
                            >
                              <div className={classNames.img}>
                                <img
                                  src={ticket?.token_profile_data?.coinImage}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className={classNames.headerPreview}>
                              <div className={classNames.title}>
                                {ticket?.token_profile_data?.coinName}
                              </div>
                              <div className={classNames.by}>
                                By{" "}
                                <img
                                  src={ticket?.banker_metaData?.profilePicURL}
                                  alt=""
                                  className={classNames.byImg}
                                />{" "}
                                <b>{ticket?.banker_metaData?.displayName}</b>
                              </div>
                            </div>
                            <div className={classNames.content}>
                              <div className={classNames.price}>
                                <div className={classNames.label}>
                                  Current Price
                                </div>
                                <div className={classNames.value}>
                                  {FormatCurrency(
                                    ticket?.token_price,
                                    ticket?.asset
                                  )}{" "}
                                  <small>{ticket?.asset}</small>
                                </div>
                              </div>
                              <div className={classNames.supply}>
                                <div className={classNames.label}>Supply</div>
                                <div className={classNames.value}>
                                  {ticket?.tokens_available}/
                                  {ticket?.token_totalSupply}
                                </div>
                              </div>
                            </div>
                            <div className={classNames.footer}>
                              <div className={classNames.country}>
                                #{ticket?.country}
                              </div>
                              <div className={classNames.circulation}>
                                <img src={addUser} alt="" />{" "}
                                <span>{ticket?.circulating_supply}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                </div>
              </div>
            </>
          )}
          <ExploreHome />
          <EventCreatorsHome />
          <div className={classNames.footerCopyright}>
            <span>All Rights Reserved 2022 tickets.social</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
