import { FULL_LOGO } from "../../configs";
import classNames from "./registerPage.module.scss";
import paste from "../../static/images/clipIcons/paste.svg";
import { useBrokerData } from "../../queryHooks";
import { useState } from "react";

function RegisterPage(): JSX.Element {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const getStep = () => {
    switch (step) {
      case 1:
        return <AffilateDetail email={email} />;

      default:
        return (
          <EnterAffilate setStep={setStep} email={email} setEmail={setEmail} />
        );
    }
  };
  return <div className={classNames.registerPage}>{getStep()}</div>;
}

function EnterAffilate({
  setStep,
  email,
  setEmail,
}: {
  setStep: (val: number) => void;
  email: string;
  setEmail: (val: string) => void;
}) {
  return (
    <>
      <div className={classNames.sidebar}>
        <div className={classNames.title}>Right Now. We Are Invite Only</div>
      </div>
      <div className={classNames.contentArea}>
        <img src={FULL_LOGO} alt="" className={classNames.logo} />
        <div className={classNames.subTitle}>
          Please Enter You’re Friends NetworkSync Code
        </div>
        <div className={classNames.inpWrap}>
          <input
            type="text"
            placeholder="friends@gmail.com...."
            className={classNames.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <img src={paste} alt="" className={classNames.paste} />
        </div>
        <div className={classNames.footerBtns}>
          <div className={classNames.btnLogin} onClick={() => {}}>
            <span>Go Back</span>
          </div>
          <div className={classNames.btnReg} onClick={() => setStep(1)}>
            <span>Proceed</span>
          </div>
        </div>
      </div>
    </>
  );
}

function AffilateDetail({
  email,
  username,
}: {
  email?: string;
  username?: string;
}) {
  const { data: brokerDetails } = useBrokerData({ email, username });
  return (
    <>
      <div className={classNames.sidebar}>
        <div className={classNames.title}>Is This Your Friend -&gt;</div>
      </div>
      <div className={classNames.contentArea}>
        <div className={classNames.user}>
          <img
            src={brokerDetails?.profile_img}
            alt=""
            className={classNames.profileImg}
          />
          <div className={classNames.name}>
            {brokerDetails?.first_name} {brokerDetails?.last_name}
          </div>
          <div className={classNames.email}>{brokerDetails?.email}</div>
        </div>
        <div className={classNames.footerBtns}>
          <div className={classNames.btnLogin} onClick={() => {}}>
            <span>Go Back</span>
          </div>
          <div className={classNames.btnReg} onClick={() => {}}>
            <span>Proceed</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
