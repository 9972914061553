import { useState } from "react";
import classNames from "./settingsModal.module.scss";
import arrowGo from "../../static/images/clipIcons/arrowGo.svg";
// import GetMobileAppModal from "../GetMobileAppModal";
import { FULL_LOGO } from "../../configs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logout, selectLoginData } from "../../app/loginSlice";

function SettingsModal({
  onClose = () => {},
  onSuccess = () => {},
  logoParam,
}: {
  onClose: () => void;
  onSuccess: () => void;
  logoParam?: string;
}) {
  const { email } = useAppSelector(selectLoginData);
  const dispatch = useAppDispatch();

  const [downloadModal, setDownloadModal] = useState(false);
  return (
    <>
      {downloadModal ? (
        // <GetMobileAppModal
        //   onClose={() => {
        //     setDownloadModal(false);
        //     onClose();
        //   }}
        //   onSuccess={() => setDownloadModal(false)}
        // />
        <></>
      ) : (
        <div className={classNames.settingsModal}>
          <div
            className={classNames.overlayClose}
            onClick={() => {
              try {
                onClose();
              } catch (error) {}
            }}
          />
          <div className={classNames.settingsCard}>
            <div className={classNames.inCard}>
              <img
                src={logoParam || FULL_LOGO}
                alt=""
                className={classNames.logo}
              />
              <div className={classNames.settingCards}>
                <div
                  className={classNames.settingCard}
                  // onClick={() => {
                  //   setTabSelected("profile");
                  //   setChatOn(true);
                  //   onClose();
                  // }}
                >
                  <span>Open Settings</span>
                  <img src={arrowGo} alt="" className={classNames.icon} />
                </div>
                <a
                  href="https://affiliate.app/"
                  target="_blank"
                  className={classNames.settingCard}
                  onClick={() => {}}
                  rel="noreferrer"
                >
                  <span>For Affiliates</span>
                  <img src={arrowGo} alt="" className={classNames.icon} />
                </a>
                <div
                  className={classNames.settingCard}
                  // onClick={() => setDownloadModal(true)}
                >
                  <span>Download Apps</span>
                  <img src={arrowGo} alt="" className={classNames.icon} />
                </div>
              </div>
            </div>
            <div className={classNames.footerBtns}>
              <div
                className={classNames.btnSettings}
                onClick={() => {
                  try {
                    if (email) {
                      dispatch(logout());
                      onClose();
                    } else onSuccess();
                  } catch (error) {}
                }}
              >
                <span>{email ? "Logout" : "Login"}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SettingsModal;
